export const eTdahAdQuestions = [
  "É atento quando conversa com alguém.",
  "É afobado no trabalho.",
  "Necessita fazer listas de tudo o que tem para fazer para não se esquecer de nada.",
  "Sente-se chateado e infeliz.",
  "Quando tem de seguir instruções (receitas, montagem de móveis), segue passo a passo e em sequência, tal como lhe é apresentado.",
  "É desorganizado financeiramente.",
  "É solitário.",
  "Termina tudo o que começa.",
  "Explode com facilidade (é do tipo pavio curto).",
  "É detalhista e minucioso.",
  "Arruma encrenca e confusão facilmente.",
  "Mostra-se insensível à dor e ao perigo.",
  "Tem sono agitado, mexe-se na cama.",
  "É bem aceito por todos.",
  "Costuma se dar mal por falar as coisas sem pensar.",
  "É persistente e insistente diante de uma ideia.",
  "Acidenta-se com facilidade (cai, tropeça, esbarra em móveis).",
  "Tende a discordar das regras e normas.",
  "Dá impressão de que não sabe o que quer.",
  "Evita trabalhos longos, detalhados e complicados.",
  "Tem dificuldade para se adaptar às mudanças.",
  "A qualidade do trabalho é comprometida porque não presta atenção suficiente.",
  "Inicia uma atividade com entusiasmo e dificilmente chega ao fim, é do tipo fogo de palha.",
  "Evita as atividades que exijam esforço mental prolongado (p. ex., leitura, filmes).",
  "Perde a paciência com os familiares.",
  "É rebelde com as pessoas e as situações.",
  "Persiste quando quer alguma coisa.",
  "Tem tendência a sonhar acordado.",
  "Faz planos cuidadosamente, considera todos os passos do começo ao fim.",
  "Parece sonhar acordado.",
  "Faz seu trabalho rápido demais.",
  "É distraído com tudo.",
  "Dificilmente chega ao final de um projeto.",
  "Seu hábito de trabalho é confuso e desorganizado.",
  "Necessita estar em constante movimentação.",
  "Atrasa os pagamentos porque se esquece das datas de vencimento.",
  "Mostra-se apático e indiferente diante das situações.",
  "Tem fortes reações emocionais (p. ex., choro, explosões de raiva, bate portas, quebra objetos, etc.)",
  "É agressivo.",
  "Tem problemas com a lei e/ou com a justiça.",
  "É imprudente, arrisca sempre.",
  "É tolerante diante das situações.",
  "Tem dificuldade para permanecer sentado, quando isso se faz necessário.",
  "É conhecido pelos outros como desligado, parecendo viver no espaço.",
  "Seu jeito de ser é motivo de discussão em casa.",
  "Tira conclusões mesmo antes de conhecer os fatos.",
  "Necessita estar em situações mais perigosas e arriscadas.",
  "Tem dificuldade em aceitar a opinião dos outros.",
  "Faz as coisas devagar, apresenta um ritmo de trabalho lento.",
  "Distrai-se enquanto trabalha e outras pessoas conversam.",
  "A mente voa longe enquanto lê.",
  "Faz tudo o que dá em sua cabeça.",
  "Costuma vingar-se das pessoas, não engole sapo.",
  "Precisa ser lembrado dos compromissos diários.",
  "Vive isolado, evita as atividades de grupo.",
  "É mais desorganizado do que a maioria das pessoas.",
  "Não observa detalhes e minúcias.",
  "Persiste até o fim com os seus objetivos, mesmo que sejam difíceis de alcançar.",
  "Sabe aguardar a vez (p. ex., fila de banco, em consultórios, etc).",
  "Responde antes de ouvir a pergunta inteira.",
  "É criticado por seu jeito de ser.",
  "Intromete-se em assuntos que não lhe dizem respeito.",
  "Costuma criticar os outros.",
  "Tem memória ruim para guardar instruções, ordens recebidas ou para decorar o que é preciso.",
  "Planeja suas ações, respeitando cada etapa do processo.",
  "É impulsivo; age antes de pensar.",
  "Costuma se esquecer de datas, números de telefone, compromissos importantes, a não ser que os anote.",
  "Necessita de novidades e de variedades em sua vida.",
  "Tem dificuldade para processar as informações recebidas."
];