import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Row, Button, Label, Progress } from 'reactstrap';

import CustomSlider from '../Inputs/CustomSlider';
import ResultsChart from '../Charts/ResultsChart';
import { StyledContainer, StyledCol } from './styles';
import { eTdahAdQuestions as questionLabels} from '../../utils/questions'

interface FormValues {
  questions: Record<string, number>;
}

interface Messages {
  [key: number]: string;
}

const initialValues: FormValues = {
  questions: questionLabels.reduce((acc, label, index) => ({ ...acc, [`q${index + 1}`]: 0 }), {})
};

const ETdahAdTest: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [incentiveMessage, setIncentiveMessage] = useState("Vamos começar, você consegue!");
  const [started, setStarted] = useState(false);
  const [responses, setResponses] = useState<FormValues | null>(null);

  const progressPercent = ((currentQuestionIndex) / questionLabels.length) * 100;
  const isLastQuestion = currentQuestionIndex === questionLabels.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  useEffect(() => {
    updateIncentiveMessage(currentQuestionIndex);
  }, [currentQuestionIndex]);

  const updateIncentiveMessage = (index: number) => {
    const totalQuestions = questionLabels.length;
    const progressPercentage = Math.floor((index / totalQuestions) * 100);
  
    if (index === 0) {
      setIncentiveMessage("Vamos começar, você consegue!");
    } else if (index === 1) {
      setIncentiveMessage("Ótimo começo, continue assim!");
    } else {
      const messages: Messages = {
        20: "Continue firme!",
        30: "Já? Mantendo o ritmo.",
        40: "Você está indo muito bem!",
        50: "Metade do caminho já! Excelente trabalho.",
        60: "Siga em frente, estamos próximos!",
        70: "Você está cada vez mais perto.",
        80: "Uau, quase lá!",
        90: "Pertinho, finalize com chave de ouro!"
      };
  
      if (messages[progressPercentage]) {
        setIncentiveMessage(messages[progressPercentage]);
      }
    }
  
    if (index === questionLabels.length - 1) {
      setIncentiveMessage("Essa é a última pergunta, juro.");
    }
  };

  const handleFinish = (values: FormValues) => {
    setResponses(values);
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const progressStyle = {
    width: '100%', 
    height: '5px', 
    backgroundColor: 'transparent', 
    backgroundImage: `linear-gradient(to right, red, green ${progressPercent}%, transparent ${progressPercent}%)`,
    backgroundSize: `${progressPercent}% 100%`,
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.5s ease'
  };

  if (!started && !responses) {
    return (
      <StyledContainer className="bg-dark" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h3 className="text-center text-white mb-4">Teste de Avaliação Comportamental ETDAH-AD</h3>
            <div className="d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                A seguir, estarão alguns termos descritivos de comportamentos que você poderá apresentar. Considere a ocorrência dos comportamentos no momento atual e nos últimos seis meses. Leia cada item cuidadosamente.
              </p>
            </div>
            <div className="mb-4 d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                Para responder, arraste clique, ou toque a barra até a opção que mais se adequar à sua opinião, indicando o grau em que o comportamento ocorre.
              </p>
            </div>
            <div className="d-flex justify-content-center justify-content-sm-center">
              <Button color="success" onClick={() => setStarted(true)} className="mr-2">
                Iniciar
              </Button>
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }

  if (started && responses) {
    return (
      <StyledContainer className="bg-dark" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h3 className="text-center text-white mb-4">Teste de Avaliação Comportamental ETDAH-AD</h3>
            <div className="d-flex text-white">
              <ResultsChart results={responses} />
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer className="bg-dark" fluid>
      <Row className="justify-content-center ml-2 mr-2">
        <StyledCol md={8} lg={8}>
          <h3 className="text-center text-white mb-4">Teste de Avaliação Comportamental ETDAH-AD</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (isLastQuestion) {
                handleFinish(values);
              } else {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
              }
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <FormikForm onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Label htmlFor={`q${currentQuestionIndex + 1}`} className="d-block mb-2">
                    <h5 className="text-white">Para a afirmação <strong><i>"{questionLabels[currentQuestionIndex]}"</i></strong>, a opção mais apropriada é:</h5>
                  </Label>
                  <div className="mt-6">
                    <CustomSlider
                      value={values.questions[`q${currentQuestionIndex + 1}`]}
                      onChange={value => setFieldValue(`questions.q${currentQuestionIndex + 1}`, value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                    <Progress bar style={progressStyle} value="100" max="100" />
                  </div>
                </div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                  <span className="text-muted small flex-shrink-0 mr-3">{incentiveMessage}</span>
                </div>
                <div className="d-flex justify-content-end justify-content-sm-center">
                  <Button color="warning" onClick={() => handlePrevious()} disabled={isFirstQuestion} className="mr-2">
                    Anterior
                  </Button>
                  <Button type="submit" color="success" className="ml-2">
                    {currentQuestionIndex === questionLabels.length - 1 ? 'Concluir' : 'Próxima'}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </StyledCol>
      </Row>
    </StyledContainer>
  );

};

export default ETdahAdTest;