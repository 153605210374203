import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface FormValues {
  questions: Record<string, number>;
}

interface CategoryScore {
  name: string;
  score: number;
  maxScore: number;
}

interface ResultsChartProps {
  results: FormValues;
}

const ResultsChart: React.FC<ResultsChartProps> = ({ results }) => {
  const calculateScores = (): CategoryScore[] => {
    const scores = {
      Desatenção: [6, 19, 20, 22, 23, 24, 28, 32, 33, 34, 36, 37, 44, 49, 50, 51, 54, 56, 57, 64, 67, 69],
      Impulsividade: [9, 11, 12, 15, 18, 25, 26, 38, 39, 40, 41, 45, 46, 47, 48, 52, 53, 60, 61, 62, 63, 66, 68],
      "Aspectos Emocionais": [4, 7, 21, 55],
      "AAMA": [1, 5, 8, 10, 14, 16, 27, 29, 42, 58, 59, 65],
      Hiperatividade: [2, 3, 13, 17, 31, 35, 43]
    };

    const categoryScores: CategoryScore[] = [];

    for (const [key, questions] of Object.entries(scores)) {
      let sum = 0;
      let maxScore = questions.length * 5; // Each question can contribute a max of 5 points
      questions.forEach(q => {
        const score = results.questions[`q${q}`];
        if (key === "Autorregulação da Atenção, da Motivação e da Ação") {
          // Inverting scores for this category
          sum += 5 - score;
        } else {
          sum += score;
        }
      });
      categoryScores.push({ name: key, score: sum, maxScore: maxScore - sum });
    }

    return categoryScores;
  };

  const data = calculateScores();

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart layout="vertical" data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" width={150} />
        <Tooltip formatter={(value: number, name: string) => [`${value} pontos`, name]} />
        <Legend formatter={(value) => value === 'score' ? 'score' : 'diferença'} />
        <Bar dataKey="score" stackId="a" fill="#8884d8" name="score" />
        <Bar dataKey="maxScore" stackId="a" fill="#ccc" name="diferença" />
      </BarChart>
    </ResponsiveContainer>

  );
};

export default ResultsChart;