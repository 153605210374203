import React from 'react';
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/argon-design-system-react.css';
import './App.css';

import ETdahAdTest from './components/ETdahAdTest';

function App() {
  return <ETdahAdTest/>
}

export default App;
