// src/components/CustomSlider/index.tsx
import React, { useEffect, useRef } from 'react';
import { SliderContainer, SliderInput, PopoverLabel } from './styles';

interface CustomSliderProps {
  value: number;
  onChange: (value: number) => void;
}

const labels = ["Nunca", "Muito raramente", "Raramente", "Geralmente", "Frequentemente", "Muito Frequentemente"];

const getColorForValue = (value: number) => {
  const percent = value / 5;
  const red = Math.round((1 - percent) * 255);
  const green = Math.round(percent * 180);
  return `rgb(${red}, ${green}, 0)`;
};

const CustomSlider: React.FC<CustomSliderProps> = ({ value, onChange }) => {
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (sliderRef.current) {
      const percent = (value / 5) * 100;
      const color = getColorForValue(value);
      sliderRef.current.style.background = `linear-gradient(to right, ${color} 0%, ${color} ${percent}%, #333 ${percent}%, #333 100%)`;
      sliderRef.current.style.setProperty('--thumb-color', color);
    }
  }, [value]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    onChange(newValue);
  };

  const adjustedLeft = value === 5 ? (value / 5) * 100 - 12 : (value / 5) * 100;

  return (
    <SliderContainer>
      <SliderInput
        ref={sliderRef}
        type="range"
        min="0"
        max="5"
        value={value}
        onChange={handleSliderChange}
      />
      <PopoverLabel style={{ left: `${adjustedLeft}%`, backgroundColor: getColorForValue(value) }}>
        <strong>{labels[value]}</strong>
      </PopoverLabel>
    </SliderContainer>
  );
};

export default CustomSlider;