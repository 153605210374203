// src/components/CustomSlider/styles.tsx
import styled from 'styled-components';

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SliderInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  outline: none;
  --thumb-color: #fff;  // Define uma cor padrão para o thumb

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--thumb-color);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--thumb-color);
    cursor: pointer;
  }
`;

export const PopoverLabel = styled.div`
  position: absolute;
  padding: 5px 10px;
  color: white;
  font-size: 0.9em;
  transform: translateX(-50%);
  bottom: 30px;
  border-radius: 5px;
  text-align: center;
  transition: left 0.3s ease, background-color 0.3s ease;
  background-color: transparent;
`;